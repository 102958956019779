import Cookies from 'js-cookie';

import './styles.scss';

const banner = document.querySelector('.gdpr-banner');
const acceptButton = document.querySelector('.gdpr-banner__button');
const cookieInfo = JSON.parse(acceptButton.dataset.cookiePolicy);

acceptButton.addEventListener('click', () => {
  Cookies.set(cookieInfo.key, cookieInfo.value, {
    sameSite: 'Lax',
    secure: process.env.RAILS_ENV === 'production',
  });
  banner.classList.add('gdpr-banner--closed');
});
